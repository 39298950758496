<template>
	<div>
		<v-textarea
			:disabled="disabled"
			:value="value"
			@change="(value) => $emit('update', value)"
		></v-textarea>
	</div>
</template>

<style lang="less">
@import "../../../../src/assets/css/survey/answerOptions.less";
</style>

<script type="text/javascript">
export default {
	name: "Comment",
	props: {
		id: {},
		value: {},
		disabled: {},
	},
	computed: {},
};
//
</script>
"
